import React from "react";
import Page from "../components/Page";
import About from "../About";

type AboutPageProps = {
  location?: {
    pathname?: string;
  };
};

const AboutPage: React.FC<AboutPageProps> = ({ location }) => {
  return (
    <Page title="About" path={location?.pathname}>
      <About />
    </Page>
  );
};

export default AboutPage;
