import React from "react";
import styled from "styled-components";
import kellyHeadshotSrc from "./assets/images/kelly_bitmoji.png";
import scottHeadshotSrc from "./assets/images/scott_bitmoji.png";
import InstagramIcon from "./assets/svgs/instagram.svg";

const Image = styled.img`
  display: block;
  margin: auto;
  margin-top: -100px;
  width: 80%;
  height: auto;
  max-width: 300px;
  border: 3px solid #07060b;
  background: #d3ddd4;
  border-radius: 100%;
`;

const Bio = styled.div`
  margin-top: 1rem;
  flex-grow: 1;
  background: white;
  padding: 3em 2em;
  p {
    margin: 1em 0;
  }
  p:first-child {
    margin-top: 0;
  }
  p:last-child {
    margin-bottom: 0;
  }
  @media screen and (min-width: 768px) {
    padding: 3em;
    line-height: 2em;
  }
`;

const StyledHost = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: hsl(4, 51%, 94%);
  color: ${(props) => props.theme.colors.text};
  margin-top: 100px;
  border-radius: 5px;
  border-bottom: 5px solid white;
  box-shadow: 0px 4px 6px 0px hsl(0, 0%, 51%);
`;

const HostName = styled.h3`
  color: hsl(4, 60%, 57%);
  font-size: 24px;
  margin: 1em 0em 1.25em 0em;
  text-align: center;
`;

const SocialContainer = styled.div`
  display: flex;
  justify-content: center;
  height: 40px;
  a {
    height: 100%;
  }
  svg {
    height: 100%;
    width: auto;
    path {
      fill: hsl(4, 60%, 57%) !important;
    }
  }
`;

type HostProps = {
  name: string;
  imageSrc: string;
  instagramLink: string;
};

const Host: React.FC<HostProps> = ({
  name,
  imageSrc,
  instagramLink,
  children,
}) => {
  return (
    <StyledHost>
      <div>
        <Image src={imageSrc} alt={name} />
        <HostName>{name}</HostName>
        <SocialContainer>
          <a href={instagramLink}>
            <InstagramIcon></InstagramIcon>
          </a>
        </SocialContainer>
      </div>
      <Bio>{children}</Bio>
    </StyledHost>
  );
};

const StyledHostSection = styled.section`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 3em;
  @media screen and (min-width: 1440px) {
    grid-template-columns: 1fr 1fr;
  }
`;

const DetailedHostSection = () => {
  return (
    <StyledHostSection>
      <Host
        name="Kelly Forst"
        imageSrc={kellyHeadshotSrc}
        instagramLink="https://www.instagram.com/plants_n_plates/"
      >
        <p>
          Kelly is a tech marketer 9-5, and amateur plant-based chef 5-9. When
          she's not experimenting with new recipes, you can find Kelly picking
          things up and putting them down in her CrossFit box, singing along on
          her Peloton, reading fantasy on the beach, and helicopter-parenting
          her pets.
        </p>
        <p>
          In the kitchen, there's nothing she enjoys more than the challenge of
          transforming bland AF tofu into a meal that barely resembles a block
          of soy.
        </p>
      </Host>
      <Host
        name="Scott Wagner"
        imageSrc={scottHeadshotSrc}
        instagramLink="https://www.instagram.com/nbbdog11/"
      >
        <p>
          Scott is a web developer by trade, and uses that to fund his cooking
          hobby. His love of food started at a young age, growing up in an
          Italian family in New York. At the top of Scott's all-time dish list
          is probably the Thanksgiving sausage stuffing his mom taught him. It
          has made an appearance for many Friendsgivings.
        </p>
        <p>
          Current food projects include: sourdough bread, seared meats with pan
          sauces, and pantry pasta dishes.
        </p>
      </Host>
    </StyledHostSection>
  );
};

export default DetailedHostSection;
