import React from "react";
import styled from "styled-components";
import description from "./description";
import DetailedHostSection from "./DetailedHostSection";

const StyledAboutPage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1em 1.5em;
  @media screen and (min-width: 768px) {
    padding: 1em 2em;
  }
`;

const AboutContainer = styled.div`
  margin: auto;
  line-height: 1.9em;
  @media screen and (min-width: 768px) {
    width: 600px;
  }
  @media screen and (min-width: 1440px) {
    width: 1000px;
  }
`;

const HostSectionContainer = styled.div`
  padding-top: 2em;
`;

const About = () => {
  return (
    <StyledAboutPage>
      <AboutContainer>
        <h2>About</h2>
        <div>
          {description}
          <p>
            We were tired of seeing media where people put other people down
            because of their opinions on food. <br />
            Our goal is to be an example of how we can have healthy discussions
            about food from differing perspectives.
          </p>
        </div>
        <HostSectionContainer>
          <DetailedHostSection />
        </HostSectionContainer>
      </AboutContainer>
    </StyledAboutPage>
  );
};

export default About;
